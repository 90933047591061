import React from "react"
import PropTypes from "prop-types"

// Components
import { Link, graphql } from "gatsby"
import {SEO, TaxLayout} from "../components/index";
import NewsletterCTA from "../components/NewsletterCTA";

const Tags = ( props ) => {
    const { tag } = props.pageContext
    const { edges, totalCount } = props.data.allMarkdownRemark
    const tagHeader = `${totalCount} post${
        totalCount === 1 ? "" : "s"
    } tagged with "${tag}"`

    return (
        <TaxLayout {...props}>
            <SEO
                title={props.pageContext.tag}
                description={'Posts tagged with ' + props.pageContext.tag}
                pathname={'/tag/' + props.pageContext.tag}
            />
            <div>
                <h1>{tagHeader}</h1>
                <ul>
                    {edges.map(({ node }) => {
                        const { url } = node.fields
                        const { title } = node.frontmatter
                        return (
                            <li key={url}>
                                <Link to={url}>{title}</Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <NewsletterCTA />
        </TaxLayout>
    )
}

Tags.propTypes = {
    pageContext: PropTypes.shape({
        tag: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            totalCount: PropTypes.number.isRequired,
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    node: PropTypes.shape({
                        frontmatter: PropTypes.shape({
                            title: PropTypes.string.isRequired,
                        }),
                        fields: PropTypes.shape({
                            url: PropTypes.string.isRequired,
                        }),
                    }),
                }).isRequired
            ),
        }),
    }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            url
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
